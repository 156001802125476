.form-login{
    width: 330px;
    padding: 15px;
}

.form-login input{
    margin-bottom: 10px;    
}

.background-login{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    object-position: left;
}
.item-blur {    
    filter: blur(.5rem);
}