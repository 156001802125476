.sidebar h5{
    height: 30px;
    margin-bottom: 0px;
}

.sidebar .footer{
    height: 265px;
}

.sidebar .produtos{
    height: calc(100% - 295px);
    overflow: auto;
    align-content: flex-start;
}

.btn-pedido{
    height: 60px;
}
 .close{    
    background-color: transparent;
    border: none;

 }
 .close img{    
    height: 32px;
    width: 32px;

 }
 .show-text {
    animation: fadeOut 5s forwards; /* 5 segundos para desaparecer */
  }
  
  /* Animação para desaparecer o texto */
  @keyframes fadeOut {
    0% {
      opacity: 1; /* Totalmente visível */
    }
    90% {
      opacity: 1; /* Visível até 90% do tempo */
    }
    100% {
      opacity: 0; /* Totalmente invisível */
    }
  }