.produto-lista .preco-antigo{
    text-decoration: line-through;
    color: #999;
}

.produto-lista a{
    text-decoration: none;
    color: rgb(51, 51, 51);
    cursor: pointer;
}

.produto-lista:hover{
    background-color: rgb(238, 238, 238);
}
