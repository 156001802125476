.form-group{
    width: 330px;
    padding: 15px;
}

.form-group input{
    margin-bottom: 10px; 
    width: 100%;   
}

.btr{ 
    width: 100%;

}