.produto-modal{

}
.detalhes-produto{
    height: 600px;
    overflow: auto;
    align-content: flex-start;
}

.img-produto-modal{
    object-fit: cover;
    width: 100%;
    height: 250px;
}

.produto-modal .promocao{
    color: #23AD6B;
}

.produto-modal .preco-antigo{
    text-decoration: line-through;
    color: #999
}

.button-qtd{
    font-size: 18pt;
    vertical-align: middle;
}