/* Container principal do formulário */
#form-checkout {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Estilização básica para os labels */
  #form-checkout label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  /* Estilização dos inputs e selects */
  #form-checkout input[type="text"],
  #form-checkout select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  /* Botão de pagamento */
  #form-checkout button[type="submit"] {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    background-color: #00b894;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  #form-checkout button[type="submit"]:hover {
    background-color: #01976a;
  }
  
  /* Responsividade */
  @media (max-width: 480px) {
    #form-checkout {
      padding: 15px;
    }
  
    #form-checkout label {
      font-size: 14px;
    }
  
    #form-checkout input[type="text"],
    #form-checkout select {
      font-size: 13px;
    }
  
    #form-checkout button[type="submit"] {
      font-size: 15px;
    }
  }
  .qr-code-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 20px;
    background-color: #f9f9f9;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .qr-code-section img {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .qr-code-section button {
    background-color: #0077cc;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .qr-code-section button:hover {
    background-color: #005fa3;
  }
  .sucess{
    text-align: center;
    color: #00b894;
  }
  
  