.endereco{

}

.endereco select{
    width: 100%;
    border: 0px;
    background-color: #fff;
    height: 30px;
    cursor: pointer;
}