.img-estabelecimento {
    max-width: 100%;
}

.estabelecimento {
    border-radius: 8px;
}

.estabelecimento a{
    color: rgb(53, 53, 53);
    text-decoration: none;
}

.estabelecimento:hover{
    background-color: rgb(238, 238, 238);
}

.avaliacao span{
    color: rgb(53, 53, 53);
    font-size: 14px;
    padding: 4px;
}
