.form-cadastro{
    width: 90%;
    padding: 15px;
}

.form-cadastro input{
    margin-bottom: 10px;    
}

.form-cadastro select{
    width: 100%;
    border: 0px;
    background-color: #fff;
    height: 43px;
    cursor: pointer;
}

.background-cadastro{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    object-position: left;
}